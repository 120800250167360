
import { defineComponent, ref, inject, watchEffect, onMounted } from "vue";
import { useStore } from "vuex";
import { Apollo, Notify } from "@/core/services";
import { GET_LOCALE_TRANSLATIONS, SEARCH_LOCALE_TRANSLATIONS } from "../graphql/Queries";
import { useI18n } from "vue-i18n";
import { GET_LOCALES } from "@/modules/common/locales/graphql/Queries";
import Search from "../../../../components/search/Search.vue";
import Table from "../../../../components/Table/Table.vue";
import InnerLoader from "../../../../components/InnerLoader.vue";
import { DELETE_LANGUAGE } from "../graphql/Mutations";
import { dropdown_handler } from "../../../../core/helpers/dropdownHandler";

export default defineComponent({
	name: "taxes-list",
	components: { Search, Table, InnerLoader },
	setup() {
		const store = useStore();
		const i18n = useI18n();
		const emitter: any = inject("emitter");
		const languages = ref([]);
		const system_locale = ref();
		const locales = ref([]) as Record<any, any>;
		const pagination = ref({}) as Record<any, any>;
		const loader = ref(false);
		const loading = ref(false);
		const clickHandler = ref() as Record<any, any>;
		const currentPage = ref(1);
		const permissions = store.getters.getPermissions;
		const languageArea = ref(false);
		const activeIndex = ref(-1);
		const search_language_query = ref(false);
		const currentLangugeLocale = ref(require(`@/assets/media/flags/${i18n.fallbackLocale.value}.svg`));

		const languageHandler = () => {
			languageArea.value = !languageArea.value;
		};

		const currentLanguage = (lang: string) => {
			return i18n.fallbackLocale.value === lang;
		};

		const columns = ref([
			{
				label: "message.LANGUAGE_ID",
				key: "id",
			},
			{
				label: "message.KEY",
				key: "key",
			},
			{
				label: "message.TRANSLATION",
				key: "translation",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);

		const filterObject = ref([
			{
				label: "message.KEY",
				value: "key",
			},
			{
				label: "message.TRANSLATION",
				value: "translation",
			},
		]);

		const dropdownHandler = (index, id) => {
			activeIndex.value = index === id ? null : id;
		};
		const handleClickOutside = (event) => {
			if (!event.target.closest(".action-btn")) {
				activeIndex.value = -1;
			}
		};

		const get_language = (page = 1, reload = false) => {
			search_language_query.value = false;
			loader.value = true;
			Apollo.watchQuery({
				query: GET_LOCALE_TRANSLATIONS,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				errorPolicy: "all",
				variables: {
					page: page,
					limit: 20,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				reload ? (currentPage.value = 1) : false;
				clickHandler.value = get_language;
				languages.value = data.Locale_translations.data;
				pagination.value = data.Locale_translations;
				loader.value = false;
			});
		};

		const setLang = async (lang: string, flag: string) => {
			system_locale.value = lang;
			currentLangugeLocale.value = flag;
			languageHandler();
			get_language(1);
		};

		const handleCurrentChange = (page: number) => {
			currentPage.value = page;
			clickHandler.value(page);
		};

		get_language(1);

		const loadLang: Record<any, any> = Apollo.watchQuery({
			query: GET_LOCALES,
			fetchPolicy: "network-only",
			nextFetchPolicy: "cache-only",
			context: {
				uri: process.env.VUE_APP_GEP_COMMON,
			},
		}).subscribe(({ data }) => {
			data.locales.forEach((element) => {
				locales.value.push({
					label: element.name,
					value: element.locale,
					flag: require(`@/assets/media/flags/${element?.locale}.svg`),
				});
			});
		});

		// loadLang();

		const handleEdit = (language: Record<any, any>) => {
			emitter.emit("editLanguageAction", language);
		};

		const searchHandler = (page = 1) => {
			search_language_query.value = true;
			const searchData = store.getters.getSearchData;
			loader.value = true;
			Apollo.watchQuery({
				query: SEARCH_LOCALE_TRANSLATIONS,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "network-only",
				variables: {
					page: page,
					limit: 20,
					filter: searchData.data.select,
					search_key: searchData.data.input,
					locale: system_locale.value,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}

				clickHandler.value = searchHandler;
				languages.value = data.search_locale_translations.data;
				pagination.value = data.search_locale_translations;
				loader.value = false;
			});
		};

		const handleDelete = (id: number) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					await Apollo.mutate({
						mutation: DELETE_LANGUAGE,
						variables: { id: id },
						update: (store, { data: { delete_language } }) => {
							// const read_data = store.readQuery({
							// 	query: GET_LOCALE_TRANSLATIONS,
							// 	variables: {
							// 		page: currentPage.value,
							// 		limit: 20,
							// 	},
							// }) as Record<any, any>;
							// const data = read_data.Locale_translations.data.filter((t: any) => t.id !== delete_language);
							// store.writeQuery({
							// 	query: GET_LOCALE_TRANSLATIONS,
							// 	data: {
							// 		Locale_translations: {
							// 			...data.Locale_translations,
							// 			data: [...data],
							// 		},
							// 	},
							// 	variables: {
							// 		page: currentPage.value,
							// 		limit: 20,
							// 		id: delete_language,
							// 	},
							// });
							search_language_query.value ? searchHandler(pagination.value.current_page) : get_language(pagination.value.current_page);
							loader.value = false;
						},
					});
					Notify.success(i18n.t("message.RECORD_DELETED_SUCCESSFULLY"));
				})
				.catch(() => {
					loader.value = false;
				});
		};

		emitter.on("reloadLocaleTranslation", () => {
			get_language(1, true);
		});

		const changeLang = (event: Record<any, any>) => {
			system_locale.value = event;
			get_language(1);
		};
		watchEffect(() => {
			system_locale.value = i18n.fallbackLocale.value;
		});

		const handleClickOutsides = (event) => {
			if (!event.target.closest(".outside-click")) {
				languageArea.value = false;
			}
		};
		onMounted(() => {
			document.addEventListener("click", handleClickOutside);
			document.addEventListener("click", handleClickOutsides);
		});

		const exportFile = (data) => {
			const searchData = store.getters.getSearchData;
			loading.value = true;
			const sub = Apollo.watchQuery({
				query: SEARCH_LOCALE_TRANSLATIONS,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				variables: {
					page: 0,
					limit: 1,
					filter: searchData?.data?.select ? searchData?.data?.select : "",
					search_key: searchData?.data?.input ? searchData?.data?.input : "",
					locale: system_locale.value,
					type: data?.type,
					date_from: data?.dateFrom,
					date_to: data?.dateTo,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loading.value = false;
				} else if (data) {
					window.open(data?.search_locale_translations?.data[0]?.path, "_blank");
					loading.value = false;
				}

				loading.value = false;
				sub.unsubscribe();
			});
		};

		return {
			languages,
			pagination,
			locales,
			system_locale,
			clickHandler,
			currentPage,
			filterObject,
			columns,
			permissions,
			loader,
			languageArea,
			currentLangugeLocale,
			loading,
			setLang,
			currentLanguage,
			exportFile,
			changeLang,
			handleCurrentChange,
			languageHandler,
			handleEdit,
			handleDelete,
			get_language,
			searchHandler,
			activeIndex,
			dropdownHandler,
			handleClickOutside,
		};
	},
});
