import { gql } from "graphql-tag";

export const LOCALE_TRANSLATION_FIELDS = gql`
  fragment LocaleTranslationFields on Language {
    id
    key
    path
    translation
  }
`;

export const PAGINATION = gql`
  fragment Pagination on languagePagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export default { LOCALE_TRANSLATION_FIELDS, PAGINATION };