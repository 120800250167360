
import { Modal } from "bootstrap";
import { defineComponent, ref, inject, watchEffect } from "vue";
import { Input, Select } from "@/components/input-elements";
import { Apollo, Notify } from "@/core/services";
import { CREATE_KEY } from "../graphql/Mutations";
import { GET_LOCALES } from "@/modules/common/locales/graphql/Queries";
import { GET_LOCALE_TRANSLATIONS } from "@/modules/system/languages/graphql/Queries";
import { useI18n } from "vue-i18n";
import i18nProvider from "@/core/services/i18nProvider";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "Add Key Language",
	components: {
		Input,
		Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const locale = ref("da");
		const locales: any = ref([]);
		const i18n = useI18n();
		const system_locale = ref();
		const addLanguageKeyForm = ref<null | HTMLFormElement>(null);
		const addLanguageKeyData = ref({
			locale_key: "",
			translation: "",
		});
		watchEffect(() => {
			system_locale.value = i18n.fallbackLocale.value;
		});
		const tempValues = ref({}) as Record<any, any>;
		const emitter: any = inject("emitter");

		// Submit Handler Request
		const submitHandlerForm = async () => {
			addLanguageKeyForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					loading.value = true;
					const formData = {
						key: addLanguageKeyData.value.locale_key,
						translation: JSON.stringify(tempValues.value),
					};
					await Apollo.mutate({
						mutation: CREATE_KEY,
						variables: {
							input: formData,
						},
						update: (store, { data: { create_key } }) => {
							const read_data = store.readQuery({
								query: GET_LOCALE_TRANSLATIONS,
								variables: {
									page: 1,
									limit: 20,
								},
							}) as Record<any, any>;
							store.writeQuery({
								query: GET_LOCALE_TRANSLATIONS,
								variables: {
									page: 1,
									limit: 20,
								},
								data: {
									Locale_translations: {
										...read_data.Locale_translations,
										data: [create_key, ...read_data.Locale_translations.data],
									},
								},
							});
							modal.value.hide();
							loading.value = false;
							emitter.emit("closeLanguageForm");
							i18nProvider.SetLocaleMessage(system_locale.value);
							Notify.success(`${i18n.t("message.RECORD_ADDED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// On change input value
		const changeLang = (e: string) => {
			addLanguageKeyData.value.translation = tempValues.value[e].name;
		};

		// update on change value input
		const updateValue = (e: string) => {
			tempValues.value[locale.value].name = e;
		};

		const loadLang = () => {
			const locales_data = Apollo.readQuery({
				query: GET_LOCALES,
			});
			locales_data.locales.forEach((element) => {
				locales.value.push({
					label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
					value: element.locale,
				});

				tempValues.value[element.locale] = { name: "" };
			});
		};

		loadLang();

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			addLanguageKeyForm.value?.resetFields();
			locale.value = "da";
			// empty json fields data
			for (const i of Object.getOwnPropertyNames(tempValues.value)) {
				tempValues.value[i].name = "";
			}
		};

		// Emitter To Open Model
		emitter.on("addLanguageKeyAction", () => {
			resetForm();
			modal.value = new Modal(document.getElementById("modal_add_key_language")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			addLanguageKeyForm,
			addLanguageKeyData,
			locale,
			locales,
			changeLang,
			updateValue,
			submitHandlerForm,
		};
	},
});
