import { gql } from "graphql-tag";
import { LOCALE_TRANSLATION_FIELDS } from "@/modules/system/languages/graphql/Fragments";

export const ADD_LANGUAGE = gql`
	mutation AddLanguage($input: FileDataInput!) {
		add_language(localeData: $input)
	}
`;

export const CREATE_KEY = gql`
	mutation AddKey($input: KeyLanguageInput!) {
		create_key(keyData: $input) {
			...LocaleTranslationFields
		}
	}
	${LOCALE_TRANSLATION_FIELDS}
`;

export const UPDATE_LANGUAGE = gql`
	mutation UpdateLanguage($input: KeyLanguageInput!) {
		update_language(language: $input) {
			...LocaleTranslationFields
		}
	}
	${LOCALE_TRANSLATION_FIELDS}
`;

export const DELETE_LANGUAGE = gql`
	mutation DeleteLanguage($id: Int!) {
		delete_language(id: $id)
	}
`;
export default { ADD_LANGUAGE, UPDATE_LANGUAGE, DELETE_LANGUAGE, CREATE_KEY };
