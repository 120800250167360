import {gql} from "graphql-tag";
import {PAGINATION, LOCALE_TRANSLATION_FIELDS} from "@/modules/system/languages/graphql/Fragments";

export const LOCALE_KEYS = gql`
    query LocaleKeys {
        locale_keys
    }
`;

export const GET_LOCALE_TRANSLATIONS = gql`
    query GetLocaleTranslations($page: Int, $limit: Int) {
        Locale_translations(limit:$limit, page:$page){
            ...Pagination
            data {
                ...LocaleTranslationFields
            }
        }
    }
    ${LOCALE_TRANSLATION_FIELDS}
    ${PAGINATION},
`;

export const SEARCH_LOCALE_TRANSLATIONS = gql`
    query SearchLocaleTranslations($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $type:String , $date_from:String , $date_to:String) {
        search_locale_translations(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...LocaleTranslationFields
            }
        }
    }
    ${LOCALE_TRANSLATION_FIELDS}
    ${PAGINATION},
`;


export default { LOCALE_KEYS, GET_LOCALE_TRANSLATIONS, SEARCH_LOCALE_TRANSLATIONS };