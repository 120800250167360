import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguageList = _resolveComponent("LanguageList")!
  const _component_LanguageHandler = _resolveComponent("LanguageHandler")!
  const _component_AddKeyLanguage = _resolveComponent("AddKeyLanguage")!
  const _component_EditLanguage = _resolveComponent("EditLanguage")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_LanguageList)
    ]),
    _createVNode(_component_LanguageHandler),
    _createVNode(_component_AddKeyLanguage),
    _createVNode(_component_EditLanguage)
  ], 64))
}