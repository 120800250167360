
import { Modal } from "bootstrap";
import { defineComponent, ref, inject, watchEffect } from "vue";
import { Apollo, Notify } from "@/core/services";
import { LOCALE_KEYS } from "../graphql/Queries";
import { GET_LOCALES } from "@/modules/common/locales/graphql/Queries";
import { Select } from "@/components/input-elements";
import { ADD_LANGUAGE } from "@/modules/system/languages/graphql/Mutations";
import i18nProvider from "@/core/services/i18nProvider";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "Language Handler",
	components: {
		Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
		const addLanguageForm = ref<null | HTMLFormElement>(null);
		const inputFile = ref<null | HTMLFormElement>(null);
		const disableExportBtn = ref(false);
		const locales = ref([]) as Record<any, any>;
		const localeKey = ref();
		const showImportInputs = ref(false);
		const fileName = ref("");
		const i18n = useI18n();
		let keyFile = ref([]) as Record<any, any>;
		const system_locale = ref();

		watchEffect(() => {
			system_locale.value = i18n.fallbackLocale.value;
		});

		const loadLang = () => {
			const locales_data = Apollo.readQuery({
				query: GET_LOCALES,
			});
			locales_data.locales.forEach((element) => {
				locales.value.push({
					value: element.locale,
					label: element.name,
				});
			});
		};

		loadLang();

		// Export Language Keys of Application
		const exportKeys = () => {
			disableExportBtn.value = true;
			Apollo.watchQuery({
				query: LOCALE_KEYS,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				errorPolicy: "all",
			}).subscribe(({ data, errors }) => {
				disableExportBtn.value = false;
				const url = window.URL.createObjectURL(new Blob([data["locale_keys"]]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "translation.json");
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(url);
			});
		};

		const triggerFile = () => {
			inputFile.value?.click();
		};

		const handleFileChange = (event: Record<any, any>) => {
			keyFile = event.target.files;
			fileName.value = event.target.files[0].name;
			if (event.target.files[0].type != "application/json") {
				keyFile = [];
				fileName.value = "";
				Notify.error(i18n.t("message.UPLOAD_JSON_FILE"));
			}
		};

		const submitLanguageForm = async () => {
			loading.value = true;
			const formData = {
				key: localeKey.value,
				file: keyFile,
			};
			await Apollo.mutate({
				mutation: ADD_LANGUAGE,
				variables: {
					input: formData,
				},
				update: (store, { data: { add_language } }) => {
					i18nProvider.SetLocaleMessage(system_locale.value);
					emitter.emit("reloadLocaleTranslation");
					modal.value.hide();
					loading.value = false;
					Notify.success(`${i18n.t("message.RECORD_ADDED_SUCCESSFULLY")}`);
				},
			}).catch(() => {
				loading.value = false;
			});
		};
		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			localeKey.value = "";
			keyFile = [];
			fileName.value = "";
			disableExportBtn.value = false;
			showImportInputs.value = false;
		};

		// Emitter To Open Model
		emitter.on("addLanguageAction", () => {
			resetForm();
			modal.value = new Modal(document.getElementById("modal_add_language")) as HTMLElement;
			modal.value.show();
		});
		emitter.on("closeLanguageForm", () => {
			modal.value.hide();
		});

		const addLanguageKey = () => {
			emitter.emit("addLanguageKeyAction");
		};

		return {
			loading,
			addLanguageForm,
			inputFile,
			disableExportBtn,
			locales,
			localeKey,
			showImportInputs,
			fileName,
			keyFile,
			loadLang,
			exportKeys,
			addLanguageKey,
			handleFileChange,
			submitLanguageForm,
			triggerFile,
		};
	},
});
