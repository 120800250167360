
import {defineComponent, onMounted} from 'vue';
import LanguageList from "@/modules/system/languages/components/LanguageList.vue";
import LanguageHandler from "@/modules/system/languages/components/LanguageHandler.vue";
import EditLanguage from "@/modules/system/languages/components/EditLanguage.vue";
import AddKeyLanguage from "@/modules/system/languages/components/AddKeyLanguage.vue";
import {setPageHeader} from "@/core/helpers/toolbar";

export default defineComponent({
    components: {
        LanguageList,
        LanguageHandler,
        AddKeyLanguage,
        EditLanguage
    },
    setup() {
        onMounted(() => {
            setPageHeader({
                title: "message.LANGUAGES",
                actionButton: {
                    ability: 'add_language',
                    pageAction: {
                        action: "addLanguageAction",
                    },
                    button: {
                        icon: "bi bi-plus",
                        openModal: true,
                    }
                },
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.SYSTEM", link: "#"},
                    { name: "message.SETTINGS", link: "/system/settings"},
                    { name: "message.LANGUAGES"}
                ]
            });
        })
    }
});
